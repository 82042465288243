import type { AxiosError } from 'axios';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { AdminOrganization } from '../admin-organization/adminOrganizationSlice';

const { logError } = RaygunErrorHandlerService();

type ResponseSet = {
  id: string;
  name: string;
  description: string;
};

type Response = {
  id: string;
  name: string;
  name_short: string;
  value: number;
  colour_code: string;
  order: number;
  response_set_id: string;
};

export enum QuestionType {
  SingleSelect = 'single_select',
  MultiSelect = 'mutli_select',
  Zero_30_number = '0_30_number',
  Slider_0_10 = 'slider_0_10',
  TEA_1_10 = 'TEA_1_10',
  Dail = 'dial',
  BodyTemperature = 'body_temperature',
}

type Question = {
  id: string;
  name: string;
  name_long: string;
  name_short: string;
  type: QuestionType;
  order: number;
  response_set_id: string;
};

type GeneratedStricts = { deploy_script: string; revert_script: string };

type GenerateScriptsParams = {
  organization_ids: string[];
  product_name: string;
  product_code: string;
  product_client_facing_name: string;
  product_id: string;
  response_sets: ResponseSet[];
  responses: Response[];
  questions: Question[];
};

export type AdminAddProductSliceType = {
  generatedScripts: GeneratedStricts | undefined;
  organizations: AdminOrganization[];
  generateScriptsParams: GenerateScriptsParams;
};

const initialResponseSetId = uuidv4();

const initialState: AdminAddProductSliceType = {
  generatedScripts: undefined,
  organizations: [],
  generateScriptsParams: {
    organization_ids: [],
    product_name: '',
    product_code: '',
    product_client_facing_name: 'Assessment',
    product_id: uuidv4(),
    response_sets: [
      {
        id: initialResponseSetId,
        name: 'Basic',
        description: 'YEAH NAWH',
      },
    ],
    responses: [
      {
        id: uuidv4(),
        name: 'Yeah',
        name_short: '',
        value: 2,
        order: 1,
        colour_code: '',
        response_set_id: initialResponseSetId,
      },
    ],
    questions: [
      {
        id: uuidv4(),
        name: 'How you doing?',
        name_short: 'You',
        name_long: '',
        type: QuestionType.SingleSelect,
        order: 1,
        response_set_id: initialResponseSetId,
      },
    ],
  },
};

export const fetchAdminOrganizations = createAsyncThunk<AdminOrganization[], undefined, AsyncThunkConfig>(
  'adminAddProduct/fetchAdminOrganizations',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v2_organization', {
        pagination: {
          current: 0,
          pageSize: 0,
          defaultPageSize: 0,
        },
        sortedInfo: new SortedInfo<AdminOrganization>('name', 'ascend'),
        filteredInfo: {},
      })) as AdminOrganization[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminAddProduct', 'fetchAdminOrganizations']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdminGeneratedQuestions = createAsyncThunk<GeneratedStricts, undefined, AsyncThunkConfig>(
  'adminAddProduct/getAdminGeneratedQuestions',
  async (_, thunkAPI) => {
    try {
      const generateScriptsParams = thunkAPI.getState().adminAddProductSlice.generateScriptsParams;
      const response = (await axios.post('v3_generate_questions_script', generateScriptsParams)) as GeneratedStricts;
      return response;
    } catch (e) {
      logError(e, ['adminAddProduct', 'getAdminGeneratedQuestions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminAddProductSlice = createSlice({
  name: 'adminAddProduct',
  initialState,
  reducers: {
    setGenerateScriptsParams: (state, action: PayloadAction<Partial<GenerateScriptsParams>>) => {
      state.generatedScripts = undefined;
      state.generateScriptsParams = { ...state.generateScriptsParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(getAdminGeneratedQuestions.fulfilled, (state, action) => {
        state.generatedScripts = action.payload;
      });
  },
});

export const { setGenerateScriptsParams } = adminAddProductSlice.actions;
